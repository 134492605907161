import React, { Fragment, useEffect } from 'react'
import Sidebar from '../Dashboard/Sidebar'
import './Profile.css';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../images/logo.png';
import { useDispatch , useSelector } from 'react-redux';
import { Link , useNavigate } from 'react-router-dom';
import { clearErrors , logoutUser } from '../../actions/userActions';

const Profile = () => {
  const {error:userError,user} = useSelector((state)=>state.loginUser);

  const dispatch = useDispatch();
  const history = useNavigate();

  const logoutSubmitHandler = () => {
    dispatch(logoutUser())
    alert.success("Logged Out successfully");
    history(`/`)

  }

  useEffect(()=>{
    if (userError) {
      alert.error(userError);
      dispatch(clearErrors())
    }
  },[dispatch,userError])
  return (
    <Fragment>
     
     <div className="dashboardMainContainer">
     <div className="bannerContainer">
          <div>
          <img src={logo} alt="logo" />
          </div>     
          <div>
            <b>{user && user.name}</b>
            <p><SearchIcon/></p>
          </div>     
        </div>
        <div className="maindashboard">
            <div>
                <Sidebar />
            </div>
            <div>
               <div className='miniProfileContainer'>
               <div className='myProfile'>
               <h1>My Profile</h1>

                    <h4>Personal Information</h4>
                </div>
                
                <div className="profileContainer">
        <div>
            <img src={user.avatar && user.avatar.url} alt={user.name} />
            <Link >{user && user.name}</Link>
        </div>
        <div>
            
            <div>
                <h4>Email</h4>
                <p>{user.email}</p>
            </div>
            
            <div>
                <h4>Full Address</h4>
                <p>{user.address}</p>
            </div>
            <div>
                <h4>Joined At</h4>
                <p>{String(user.createdAt).substr(0,10)}</p>
            </div>
            <div>
                <Link to={`/settings`}>Change Password</Link>
                <Link onClick={logoutSubmitHandler} >Logout</Link>
            </div>
        </div>
    </div>
            </div>
  <div className='secondProfileContainer'>
 
   

  </div>
 
            </div>
        </div>

   </div>



    



    </Fragment>
  )
}

export default Profile