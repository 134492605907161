import React, { Fragment, useEffect, useState } from 'react';
import './Signup.css';
import { registerUserAction , clearErrors } from '../../actions/userActions';
import {useDispatch , useSelector} from 'react-redux';
import {useAlert} from 'react-alert';
import Loader from '../Loader/Loader';
import {useNavigate} from 'react-router-dom';
import MetaData from '../MetaData/MetaData';

const Signup = () => {
  const dispatch = useDispatch();
  const {loading,error,isAuthenticatedUser} = useSelector((state)=>state.loginUser);
  const [avatarPreview , setAvatarPreview] = useState("/Profile.png");
  const [avatar, setAvatar] = useState("/Profile.png");

  const alert = useAlert();
  const history = useNavigate();

  const [user,setUser] = useState({
    name:"",
    email:"",
    password:"",
    address:"",
    institute:""
  })

  const {name, email, password, address , institute} = user;




  const registerDataChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      }

      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUser({...user,[e.target.name]:e.target.value});
      
    }
  };


  const registerSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name",name);
    myForm.set("email",email);
    myForm.set("password",password);
    myForm.set("avatar",avatar);
    myForm.set("address",address);
    myForm.set("institute",institute);

    dispatch(registerUserAction(myForm))
  };


  useEffect(()=>{
    if (error) {
      alert.error(error);
      dispatch(clearErrors())
    };
    if (isAuthenticatedUser) {
      history(`/dashboard`)
    }
  },[error,alert,dispatch,isAuthenticatedUser,history])
  
  return (
    <Fragment>
      {
        loading ? (<Loader/>):(
          <Fragment>
            <MetaData title={`TLH - Sign-up`} />
          <div className="containerLogihUser">
                 
                 <section>
                  <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> 
               
                 <div className="signin"> 
               
                  <div className="content"> 
               
                   <h2>STEAM CURRICULUM  </h2> 
                   <h2>Sign Up</h2> 
               
                   <form className="form" encType='multipart/form-data' onSubmit={registerSubmitHandler}> 
               
                    <div className="inputBox"> 
               
                     <input type="text" required value={name} name='name' onChange={registerDataChange} /> <i>
                      Name</i> 
               
                    </div> 
                    
                    <div className="inputBox"> 
               
                     <input type="email" name='email' required value={email} onChange={registerDataChange}/> <i>
                      Email</i> 
               
                    </div> 
                    <div className="inputBox"> 
               
               <input type="text" required value={institute} onChange={registerDataChange} name='institute' /> <i>
                Institute Name</i> 
         
              </div> 
              <div className="inputBox"> 
               
               <input type="text" required value={address} onChange={registerDataChange} name='address'/> <i>
                Address</i> 
         
              </div> 
               
                    <div className="inputBox"> 
               
                     <input type="password" required name='password' value={password} onChange={registerDataChange} /> <i>Password</i> 
               
                    </div> 
                    <div className="inputBox" id='avatarPreview'> 
      
                   <img 
                   src={avatarPreview} 
                   alt="Avatar Preview" 
                   className='avatarImage' />
                   <input 
                   type="file"
                  name="avatar" 
                  accept='image/*'
                  onChange={registerDataChange}
                   />
         
              </div> 
                  
               
                    <div className="inputBox"> 
               
                     <input type="submit" value="Signup" /> 
               
                    </div> 
               
                   </form> 
               
                  </div> 
               
                 </div> 
               
                </section></div> 
      </Fragment>
        )
      }
    </Fragment>
  )
}

export default Signup