import React, { Fragment, useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import {useAlert} from 'react-alert';
import { useSelector , useDispatch } from 'react-redux';
import logo from '../../images/logo.png';
import { clearErrors  } from '../../actions/userActions';
import { useNavigate} from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import { createDashboardAction  } from '../../actions/dashboardActions';
import Loader from '../Loader/Loader';
import { CREATE_DASHBOARD_RESET } from '../../constants/dashboardConstants';


const CreateDashboard = () => {

  const {loading,error,success} = useSelector((state)=>state.createDashboard);

  const {error:userError,user} = useSelector((state)=>state.loginUser);
  const alert = useAlert();
  const dispatch = useDispatch();

  const history = useNavigate();

  const [avatarPreview , setAvatarPreview] = useState("/Profile.png");

  const [image, setImage] = useState("/Profile.png");

  const [name , setName] = useState("");
  const [youtube , setYoutube] = useState("");
  const [activeTopic , setActiveTopic] = useState("");
  const [downloadStandards , setDownloadStandards] = useState("");
  const [skill1Name , setSkill1Name] = useState("");
  const [skill2Name , setSkill2Name] = useState("");
  const [skill3Name , setSkill3Name] = useState("");
  const [skill4Name , setSkill4Name] = useState("");
  const [skill5Name , setSkill5Name] = useState("");

  const [skill1Percentage , setSkill1Percentage] = useState(0);
  const [skill2Percentage , setSkill2Percentage] = useState(0);
  const [skill3Percentage , setSkill3Percentage] = useState(0);
  const [skill4Percentage , setSkill4Percentage] = useState(0);
  const [skill5Percentage , setSkill5Percentage] = useState(0);

  

  const newDataChange = (e) => {

       if (e.target.name === "image") {
        const reader = new FileReader();

        reader.onload = () => {
          if (reader.readyState === 2) {
            setAvatarPreview(reader.result);
            setImage(reader.result);
          }
        }
        reader.readAsDataURL(e.target.files[0]);

       }
  }

  const createDashboardSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("youtube",youtube);
    myForm.set("activeTopic",activeTopic);
    myForm.set("downloadStandards",downloadStandards);
    myForm.set("skill1Name",skill1Name);
    myForm.set("skill2Name",skill2Name);
    myForm.set("skill3Name",skill3Name);
    myForm.set("skill4Name",skill4Name);
    myForm.set("skill5Name",skill5Name);
    myForm.set("skill1Percentage",skill1Percentage);
    myForm.set("skill2Percentage",skill2Percentage);
    myForm.set("skill3Percentage",skill3Percentage);
    myForm.set("skill4Percentage",skill4Percentage);
    myForm.set("skill5Percentage",skill5Percentage);
    myForm.set("image",image);

    dispatch(createDashboardAction(myForm));

  }


  useEffect(()=>{
    if (userError) {
      alert.error(userError)
      dispatch(clearErrors())   
    }
    if (error) {
      alert.error(error)
      dispatch(clearErrors())
    };
    if (success) {
      alert.success(`Standards Created Successfully`)
      history(`/admin-panel`);
      dispatch({
        type:CREATE_DASHBOARD_RESET
      })
    }
  },[userError,dispatch,alert,error,success,history])
  return (
   
         <Fragment>
          {
            loading ? (<Loader />):(
              <Fragment>
              <div className='dashboardMainContainer'>
                
            <div className="bannerContainer">
                      <div>
                      <img src={logo} alt="logo" />
                      </div>     
                      <div>
                        <b>{user && user.name}</b>
                        <p><SearchIcon/></p>
                      </div>     
                        </div>
                <div className="maindashboard">
             
                    <div>
                        <AdminSidebar />
                    </div>
                    <div>
                     
                       <div className="updatePasswordContainer">
                  <div className="updatePasswordForm">
                    <h1 className="title">Create Dashboard </h1>
                    <form onSubmit={createDashboardSubmitHandler} >
                     
                      <label>
                        <input type="text" placeholder="Please Enter Subject Name" className="input"  value={name} onChange={(e)=>setName(e.target.value)}  />
                      </label>
                      <label>
                        <input type="text" placeholder="Please Enter Youtube Embed Link" className="input"  value={youtube} onChange={(e)=>setYoutube(e.target.value)}  />
                      </label>
                      <label>
                        <input type="text" placeholder="Please Enter Active Topic" className="input"  value={activeTopic} onChange={(e)=>setActiveTopic(e.target.value)}  />
                      </label>
                      <label>
                        <input type="text" placeholder="Please Enter Download Standard PDF Link" className="input" 
                        value={downloadStandards} 
                        onChange={(e)=>setDownloadStandards(e.target.value)} 
                         />
                      </label>
                      
                      <label>
                        <input type="text" placeholder="Please Enter Skill 1 Name" className="input" 
                        value={skill1Name} 
                        onChange={(e)=>setSkill1Name(e.target.value)}
                          />
                      </label>
                      <label>
                        <input type="Number" placeholder="Please Enter Skill 1 Percent" className="input" value={skill1Percentage}
                        onChange={(e)=>setSkill1Percentage(e.target.value)}   />
                      </label>
                      <label>
                        <input type="text" placeholder="Please Enter Skill 2 Name" className="input" 
                        value={skill2Name} 
                        onChange={(e)=>setSkill2Name(e.target.value)} 
                        />
                      </label>
                      <label>
                        <input type="Number" placeholder="Please Enter Skill 2 Percent" className="input" value={skill2Percentage} onChange={(e)=>setSkill2Percentage(e.target.value)}   />
                      </label>
                      <label>
                        <input type="text" placeholder="Please Enter Skill 3 Name" className="input"  
                        value={skill3Name} onChange={(e)=>setSkill3Name(e.target.value)}  />
                      </label>
                      <label>
                        <input type="Number" placeholder="Please Enter Skill 3 Percent" className="input" value={skill3Percentage} onChange={(e)=>setSkill3Percentage(e.target.value)}   />
                      </label>
                      <label>
                        <input type="text" placeholder="Please Enter Skill 4 Name" className="input" value={skill4Name} onChange={(e)=>setSkill4Name(e.target.value)}   />
                      </label>
                      <label>
                        <input type="Number" placeholder="Please Enter Skill 4 Percent" className="input"  value={skill4Percentage} onChange={(e)=>setSkill4Percentage(e.target.value)}  />
                      </label>
                      <label>
                        <input type="text" placeholder="Please Enter Skill 5 Name" className="input" value={skill5Name} onChange={(e)=>setSkill5Name(e.target.value)}  />
                      </label>
                      <label>
                        <input type="Number" placeholder="Please Enter Skill 5 Percent" className="input"  value={skill5Percentage} onChange={(e)=>setSkill5Percentage(e.target.value)}  />
                      </label>
                      <div className="inputBox" id='avatarPreview'> 
          
          <img 
          alt="Avatar Preview" 
          className='avatarImage' 
          src= {avatarPreview}
          />
          <input 
          type="file"
         name="image" 
         accept='image/*'
         onChange={newDataChange}
          />
    
     </div> 
                      <button type="submit" className="buttonBtn">Confirm  
                      </button>
                    </form>
                  </div>
                </div>
            
                    </div>
                </div>
              </div>
            
            
            
            </Fragment>
            )
          }
         </Fragment>
     
  )
}

export default CreateDashboard